import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Hero = ({ data }) => {
  const hiro = [];

  const result = data.filter(checkImage);

  function checkImage(element) {
    return element.image;
  }

  // let list = data[data.length - 1];
  // hiro.push(list);

  for (let index = 0; index < 5; index++) {
    const element = data[index];
    hiro.push(element);
  }
  return (
    <div className="tile is-ancestor m-0">
      {/* <pre>{JSON.stringify({ data }, null, 2)}</pre> */}
      <div className="tile is-parent is-6 hf-1">
        <article
          className="tile is-child"
          style={{
            backgroundImage: `url(${hiro[0]?.image})`,
          }}
        >
          <Link to={`/${hiro[0]?.slug}`}>
            <div className="hf-details">
              <div className="category">
                <span>{hiro[0]?.categories[0]?.name}</span>
              </div>
              <div className="title">{hiro[0]?.title}</div>
            </div>
          </Link>
        </article>
      </div>
      <div className="tile is-vertical">
        <div className="tile is-parent hf-2">
          <article
            className="tile is-child"
            style={{
              backgroundImage: `url(${hiro[1]?.image})`,
            }}
          >
            <Link to={`/${hiro[1]?.slug}`}>
              <div className="hf-details">
                <div className="category">
                  <span>{hiro[1]?.categories[0]?.name}</span>
                </div>
                <div className="title med">{hiro[1]?.title}</div>
              </div>
            </Link>
          </article>
        </div>
        <div className="tile">
          <div className="tile is-parent is-vertical hf-3">
            <article
              className="tile is-child"
              style={{
                backgroundImage: `url(${hiro[2]?.image})`,
              }}
            >
              <Link to={`/${hiro[2]?.slug}`}>
                <div className="hf-details">
                  <div className="category">
                    <span>{hiro[2]?.categories[0]?.name}</span>
                  </div>
                  <div className="title sem">{hiro[2]?.title}</div>
                </div>
              </Link>
            </article>
          </div>
          <div className="tile is-parent hf-3">
            <article
              className="tile is-child"
              style={{
                backgroundImage: `url(${hiro[3]?.image})`,
              }}
            >
              <Link to={`/${hiro[3]?.slug}`}>
                <div className="hf-details">
                  <div className="category">
                    <span>{hiro[3]?.categories[0]?.name}</span>
                  </div>
                  <div className="title sem">{hiro[3]?.title}</div>
                </div>
              </Link>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
